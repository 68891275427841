export const CONFIG = {
  //Staging
   MonolithicApi: "https://beta-api.ratemetrics.com/",
  // MonolithicApi:"http://localhost:3001/",
   UiUrl: "https://beta.ratemetrics.com/",
   HeaderClass: "stagging-header",
   IsStaging: true,
   MapboxToken: "pk.eyJ1IjoiYWktdGhnLW1hcGJveCIsImEiOiJjbHFkcWVvMjUwMjhiMmlueWFxamEzbGhwIn0.2Lreu1Hr5nle_khGjKJ08A"
  //Live

  // MonolithicApi: "https://live-api.ratemetrics.com/",
  // UiUrl : "https://hotel.ratemetrics.com/",
// HeaderClass: "live-header",
//IsStaging :false

};
